import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import * as styles from "./why-hiringmaster.module.css";
import Layout from "../components/Layout/Layout";
import Join from "../components/Join/Join";
import sanitizeHtml from "sanitize-html";
import avatar from "../images/why-hiringmaster/rex-writer.svg";
import controlTab from "../images/why-hiringmaster/control-tab.svg";
import insightsTab from "../images/why-hiringmaster/insights-tab.svg";
import collaborationTab from "../images/why-hiringmaster/collaboration-tab.svg";
import brandingTab from "../images/why-hiringmaster/branding-tab.svg";
import Seo from "../components/shared/Seo";

const images = [controlTab, insightsTab, collaborationTab, brandingTab];

export default function WhyHiringmaster() {
  const { t } = useTranslation();
  const benefitsTabs = Array.from(t("why_hm_page.benefits.tabs"));
  let [selectedTab, setSelectedTab] = useState(0);
  return (
    <Layout>
      {/* Header_DIV*/}
      <Seo title="Why Hiringmaster?" description="Simplify your recruitment process and take control of your hiring. Find out how Hiringmaster can transform the way you work." canonicalPath="/why-hiringmaster"/>
      <div className={styles.header}>
        <div className={styles.infos}>
          <h1 className={styles.title}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(t("why_hm_page.title")) }} />
          <p className={styles.description}>{t("why_hm_page.description")}</p>
        </div>
      </div>
      {/* Our philosophy_DIV*/}
      <div className={styles.secondSection}>
        <h6 className={styles.subTitle}>{t("why_hm_page.our_philosophy.sub_title")}</h6>
        <h1 className={styles.mainTitle}>{t("why_hm_page.our_philosophy.main_title")}</h1>
        <p className={styles.comment}>{t("why_hm_page.our_philosophy.description")}</p>
        <div className={styles.ourPhilosophySections}>
          {Array.from(t("why_hm_page.our_philosophy.sections")).map((section, index) => {
            return (
              <div className={styles.ourPhilosophySection} key={index}>
                <h6 className={styles.ourPhilosophySectionTitle}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.title) }} />
                <p className={styles.ourPhilosophySectionDescription}> {section.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      {/* Why hiringmaster_DIV*/}
      <div className={styles.thirdSection}>
        {Array.from(t("why_hm_page.why_hm.sections")).map(section => {
          return (
            <div className={styles.whyHmSectionDiv}>
              <div className={styles.whyHmSectionTitleDiv}>
                <h1 className={styles.whyHmSectionTitle}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.title) }} />
              </div>
              <div className={styles.whyHmSectionSubSections}>
                {Array.from(section.sub_sections).map((subSection, index) => {
                  return (
                    <div className={styles.whyHmSectionSubSection} key={index}>
                      <h6 className={styles.whyHmSectionSubSectionTitle}>{subSection.title}</h6>
                      <p className={styles.whyHmSectionSubSectionDescription}>{subSection.description}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {/* REX section_DIV */}
      <div className={styles.rexSection}>
        <div className={styles.rexSectionCard}>
          <p className={styles.rexMessage}>{t("why_hm_page.rex.message")}</p>
          <div className={styles.rexWriter}>
            <img className={styles.rexWriterImage} src={avatar} alt="avatar" />
            <div className={styles.rexWriterText}>
              <p className={styles.rexWriterName}>{t("why_hm_page.rex.author.name")}</p>
              <p className={styles.rexWriterPosition}>{t("why_hm_page.rex.author.position")}</p>
            </div>
          </div>
        </div>
      </div>
      {/* benefits section_DIV*/}
      <div className={styles.benefitsSection}>
        <div className={styles.benefitsSectionText}>
          <h1 className={styles.benefitsSectionTitle}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(t("why_hm_page.benefits.title")) }} />
          <p className={styles.benefitsSectionDescription}>{t("why_hm_page.benefits.description")}</p>
        </div>
        <div className={styles.benefitsSectionTabs}>
          <div className={styles.benefitsSectionTabsTitles}>
            {benefitsTabs.map((tab, index) => {
              return (
                <button
                  className={`${styles.benefitsSectionTabsTitle} ${index === selectedTab && styles.benefitsSectionTabsTitleSelected}`}
                  onClick={() => {
                    setSelectedTab(index);
                  }}>{tab.tab_title}</button>
              );
            })}
          </div>
          <div className={styles.benefitsSectionTabsDetails}>
            <div className={styles.benefitsSectionTabsDetailsText}>
              <h6 className={styles.benefitsSectionTabsDetailsTitle}>{benefitsTabs[selectedTab].main_title}</h6>
              <p className={styles.benefitsSectionTabsDetailsDescription}
                 dangerouslySetInnerHTML={{ __html: sanitizeHtml(benefitsTabs[selectedTab].description) }} />
            </div>
            <div className={styles.benefitsSectionTabsDetailsImageDiv}>
              <img className={styles.benefitsSectionTabsDetailsImage} src={images[selectedTab]}
                   alt="description-image" />
            </div>
          </div>
        </div>
      </div>
      {/* message section_DIV*/}
      <div className={styles.messageSection}>
        <p className={styles.messageSectionText}>
          {t("why_hm_page.message")}
        </p>
      </div>
      {/* all in one section_DIV*/}
      <div className={styles.allInOneSection}>
        <div className={styles.allInOneSectionText}>
          <h1 className={styles.allInOneSectionTitle}>{t("why_hm_page.all_in_one.title")}</h1>
          <p className={styles.allInOneSectionSubTitle}>{t("why_hm_page.all_in_one.sub_title")}</p>
        </div>
        <div className={styles.allInOneSectionAdvantages}>
          {Array.from(t("why_hm_page.all_in_one.advantages")).map((advantage, index) => {
            return (
              <div className={styles.allInOneSectionAdvantage} key={index}>
                <h6 className={styles.allInOneSectionAdvantageTitle}>{advantage.title}</h6>
                <p className={styles.allInOneSectionAdvantageDescription}>{advantage.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Join />
    </Layout>
  );
}
