// extracted by mini-css-extract-plugin
export var allInOneSection = "why-hiringmaster-module--all-in-one-section--WoZOG";
export var allInOneSectionAdvantage = "why-hiringmaster-module--all-in-one-section-advantage--MWQ+e";
export var allInOneSectionAdvantageDescription = "why-hiringmaster-module--all-in-one-section-advantage-description--s29g3";
export var allInOneSectionAdvantageTitle = "why-hiringmaster-module--all-in-one-section-advantage-title--kr11p";
export var allInOneSectionAdvantages = "why-hiringmaster-module--all-in-one-section-advantages--bZPfV";
export var allInOneSectionSubTitle = "why-hiringmaster-module--all-in-one-section-sub-title--wbJ6m";
export var allInOneSectionText = "why-hiringmaster-module--all-in-one-section-text--FOQAc";
export var allInOneSectionTitle = "why-hiringmaster-module--all-in-one-section-title--9Ge8y";
export var benefitsSection = "why-hiringmaster-module--benefits-section--p7-Lx";
export var benefitsSectionDescription = "why-hiringmaster-module--benefits-section-description--GkQSF";
export var benefitsSectionTabs = "why-hiringmaster-module--benefits-section-tabs--+tYTM";
export var benefitsSectionTabsDetails = "why-hiringmaster-module--benefits-section-tabs-details--KnIH3";
export var benefitsSectionTabsDetailsDescription = "why-hiringmaster-module--benefits-section-tabs-details-description--+Zszj";
export var benefitsSectionTabsDetailsImage = "why-hiringmaster-module--benefits-section-tabs-details-image--po6f1";
export var benefitsSectionTabsDetailsImageDiv = "why-hiringmaster-module--benefits-section-tabs-details-image-div--frnRN";
export var benefitsSectionTabsDetailsText = "why-hiringmaster-module--benefits-section-tabs-details-text--ew9Eg";
export var benefitsSectionTabsDetailsTitle = "why-hiringmaster-module--benefits-section-tabs-details-title--txQOA";
export var benefitsSectionTabsTitle = "why-hiringmaster-module--benefits-section-tabs-title--4mC5M";
export var benefitsSectionTabsTitleSelected = "why-hiringmaster-module--benefits-section-tabs-title-selected--k2l2L";
export var benefitsSectionTabsTitles = "why-hiringmaster-module--benefits-section-tabs-titles--C-HWi";
export var benefitsSectionText = "why-hiringmaster-module--benefits-section-text--GrI0e";
export var benefitsSectionTitle = "why-hiringmaster-module--benefits-section-title--XOwOF";
export var comment = "why-hiringmaster-module--comment--x4zAY";
export var description = "why-hiringmaster-module--description--XzMvo";
export var header = "why-hiringmaster-module--header--PXm0-";
export var infos = "why-hiringmaster-module--infos--nlOAt";
export var mainTitle = "why-hiringmaster-module--main-title--PBC9k";
export var messageSection = "why-hiringmaster-module--message-section--Lcv9o";
export var messageSectionText = "why-hiringmaster-module--message-section-text--Y7A4q";
export var ourPhilosophySection = "why-hiringmaster-module--our-philosophy-section--eVxJe";
export var ourPhilosophySectionDescription = "why-hiringmaster-module--our-philosophy-section-description--Sb06v";
export var ourPhilosophySectionTitle = "why-hiringmaster-module--our-philosophy-section-title--M8vz8";
export var ourPhilosophySections = "why-hiringmaster-module--our-philosophy-sections--JRILw";
export var rexMessage = "why-hiringmaster-module--rex-message--K0DDN";
export var rexSection = "why-hiringmaster-module--rex-section--Tu3+2";
export var rexSectionCard = "why-hiringmaster-module--rex-section-card--355CA";
export var rexWriter = "why-hiringmaster-module--rex-writer--8gq4R";
export var rexWriterImage = "why-hiringmaster-module--rex-writer-image--uVU+Z";
export var rexWriterName = "why-hiringmaster-module--rex-writer-name--2qA28";
export var rexWriterPosition = "why-hiringmaster-module--rex-writer-position--8t347";
export var rexWriterText = "why-hiringmaster-module--rex-writer-text--IIdUe";
export var secondSection = "why-hiringmaster-module--second-section--Q3q4e";
export var subTitle = "why-hiringmaster-module--sub-title--RF7w0";
export var thirdSection = "why-hiringmaster-module--third-section--7JwbX";
export var title = "why-hiringmaster-module--title--hO191";
export var whyHmSectionDiv = "why-hiringmaster-module--why-hm-section-div--JuqJr";
export var whyHmSectionSubSection = "why-hiringmaster-module--why-hm-section-sub-section--KvHn2";
export var whyHmSectionSubSectionDescription = "why-hiringmaster-module--why-hm-section-sub-section-description--fMfo2";
export var whyHmSectionSubSectionTitle = "why-hiringmaster-module--why-hm-section-sub-section-title--pkC3i";
export var whyHmSectionSubSections = "why-hiringmaster-module--why-hm-section-sub-sections--OVfzT";
export var whyHmSectionTitle = "why-hiringmaster-module--why-hm-section-title--4Lyov";
export var whyHmSectionTitleDiv = "why-hiringmaster-module--why-hm-section-title-div--7gbpc";